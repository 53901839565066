$ = jQuery;

$('#formEmailFooter, #formEmailPost').submit(function (e) {
  e.preventDefault()

  $('#inputEmailFooter').val() !== "" ? email_newsletter = $('#inputEmailFooter').val() : email_newsletter = $('#inputEmailPost').val()

  if($(this).valid()){

    $.ajax({
      url: ajax_handler_newsletter.url,
      type: 'POST',
      data: {
        action: 'jc_ajax_check_user_exists',
        lead_email: email_newsletter
      },
      beforeSend: function () {
        $('#btn-open-newsletter-modal').attr("disabled", true)
      },
      success: function (response) {
        $('#btn-open-newsletter-modal').removeAttr("disabled")

        if(response.result){
          $('#newsletterModalSuccess').on('show.bs.modal', function (e) {
            $('#newsletterModalSuccessTitle').text(response.title);
            $('#newsletterModalSuccessText').text(response.message);
          });

          $('#newsletterModalSuccess').modal('show');
        } else {
          $('#newsletterModal').modal('show');
        }
      },
    });
  }
})

$('#inputEmailFooter').val() !== "" ? email_newsletter = $('#inputEmailFooter').val() : email_newsletter = $('#inputEmailPost').val()
$('#newsletterModal').on('show.bs.modal', function (e) {
  $('.email').val(email_newsletter)
});

formNewsletterValidation();

$('#form-newsletter').submit(function (e) {

  e.preventDefault();

  const input_name    = $('#name');
  const form = $('#form-newsletter');

    // Pega o texto e os links do disclaimer
    var disclaimer = [];
    var lgpd_disclaimer = [];
    form.find('.lgpd-disclaimer').each(function() {
      if($(this).find('input:checked').length > 0){
        var text  = $(this).text().replace(/\\n|\s{2,}/gm, ' ').trim();
        var url = $(this).find('a');
        var url_ref = '';
        url.each(function(){
          url_ref += $(this).attr('href');
          url_ref += ' ; ';
        });
        disclaimer = {
          text_disclaimer    :text,
          url_disclaimer     :url_ref
        };
        lgpd_disclaimer.push(disclaimer);
      }
    });

  const userNewsletter = {
    'lead_email'     : $('.email').val(),
    'fullname'       : input_name.val(),
    'lead_nome'      : textNormalize(input_name.val().split(' ')[0]),
    'lead_sobrenome' : textNormalize(input_name.val().substr(input_name.val().indexOf(' ') + 1)),
    'lead_telefone'  : $('.phone').val(),
    'lead_cpf_cnpj' : $('.cpfcnpj').val(),
    'lead_cep'       : $('.cep').val(),
    'url_page'       : window.location.href,
    'lgpd_disclaimer': lgpd_disclaimer
  }

  if ($('#form-newsletter').valid()) {

    $.ajax({
      url: ajax_handler_newsletter.url,
      type: 'POST',
      data: {
        action: 'jc_ajax_enqueue_newsletter_user',
        data: userNewsletter
      },
      beforeSend: function () {
        $('#submitUserData').attr("disabled", true)
      },
      success: function (response) {
        $('#submitUserData').removeAttr("disabled")
        $('#form-newsletter')[0].reset()

        $('#newsletterModal').modal('hide');

        $('#newsletterModalSuccess').on('show.bs.modal', function (e) {
          $('#newsletterModalSuccessTitle').text(response.title);
          $('#newsletterModalSuccessText').text(response.message);
        });

        $('#newsletterModalSuccess').modal('show');
      },
    });
  }
})

function textNormalize(strVal){
  return strVal.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
}

function formNewsletterValidation(){
  validatorConfig();
  formValidateConfig($('#form-newsletter'));
  maskPhone($('.phone'));
  maskCpfCnpj($('.cpfcnpj'));
  maskCep($('.cep'))
  validatorEmail();
  validatorPhone();
  validatorCpfCnpj();
  validatorCep();
}
