
function getLocationUser() {
  if (navigator.geolocation) {
    return new Promise(function (resolve, reject) {
      navigator.geolocation.getCurrentPosition(function (position) {
        resolve({"latitude" : position.coords.latitude, "longitude" : position.coords.longitude});
      });
    });
  }
  return false;
}

function maskPhone ( element ){

  if ( element.length > 0 ) {

    element.keydown(function () {
      var  a = function (e) {
        return 11 === e.replace(/\D/g, "").length ? "(00) 00000-0000" : "(00) 0000-00009";
      },
        t = {
          onKeyPress: function (e, t, o, r) {
            o.mask(a.apply({}, arguments), r);
          }
        };

      $(this).mask(a, t);
    });

  }

}

function validatorBirthdate() {
  $.validator.addMethod("birthdate_validator", function (birthdate) {
    return validateBirthdate(birthdate);
  });
}

function validatorPhone(){
  $.validator.addMethod("phone_validator", function (phone) {
    return validatePhone(phone);
  });
}

function validateBirthdate(birthdate) {

  if(birthdate === "") return true;

  var  birthdateYear = birthdate.split('-')[0];
  return (new Date().getFullYear() - birthdateYear) >= 18;
}

function validatePhone ( phone ){

  phone = phone.replace(/\D/g, '');

  if (phone === "") {
    return true;
  }

  if (!(phone.length >= 10 && phone.length <= 11)) return false;
  if (phone.length == 11 && parseInt(phone.substring(2, 3)) != 9) return false;

  for (var  n = 0; n < 10; n++) {
    if (phone == new Array(11).join(n) || phone == new Array(12).join(n)) return false;
  }

  var  codigosDDD = [11, 12, 13, 14, 15, 16, 17, 18, 19,
      21, 22, 24, 27, 28, 31, 32, 33, 34,
      35, 37, 38, 41, 42, 43, 44, 45, 46,
      47, 48, 49, 51, 53, 54, 55, 61, 62,
      64, 63, 65, 66, 67, 68, 69, 71, 73,
      74, 75, 77, 79, 81, 82, 83, 84, 85,
      86, 87, 88, 89, 91, 92, 93, 94, 95,
      96, 97, 98, 99];

  if (codigosDDD.indexOf(parseInt(phone.substring(0, 2))) == -1) return false;

  return true;

}

function validatorEmail(){
  $.validator.addMethod("emailCustom", function ( email, element ) {
    return this.optional(element) || validateEmail(email);
  });
}

function validateEmail ( email ){
  return /^([\w.!#$%&'*+-\/=?^_`{|}~]+)([@])([\w]+)([.][A-Za-z]+|[.][A-Za-z]+[.][A-Za-z]+)$/.test(email);
}

function maskCep ( element ){

  if ( element.length > 0 ) {

    element.keydown(function () {
      var  size = $(this).val().length;
      if (size > 0) {
        $(this).mask('00000-000');
      }
    });

  }

}

function validatorCep(){

  $.validator.addMethod("cep", function (cep, element) {
    return this.optional(element) || validateCep(cep);
  });

}

function validateCep( cep ){
  return /^[0-9]{5}-[0-9]{3}$/.test(cep);
}

function maskCpfCnpj ( element ){

  if ( element.length > 0 ) {

    element.keydown(function (e) {
      var  size = $(this).val().length;
      if (size < 14) {
        $(this).mask("000.000.000-00");
      }
      else if (((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105))) {
        $(this).mask("00.000.000/0000-00");
      }
    });

    element.bind('paste', function (e) {
      var  size = e.originalEvent.clipboardData.getData('text').replace(/\D/g,'').length;
      if (size == 11) {
        $(this).mask("000.000.000-00");
        return true;
      } else if ( size == 14 ) {
        $(this).mask("00.000.000/0000-00");
        return true;
      }
    });
  }

}

function maskCpf ( element ){

  if ( element.length > 0 ) {

    element.keydown(function (e) {
      $(this).mask("000.000.000-00");
    });

    element.bind('paste', function (e) {
        $(this).mask("000.000.000-00");
        return true;
    });

  }

}

function maskCnpj ( element ){

  if ( element.length > 0 ) {

    element.keydown(function (e) {
      $(this).mask("00.000.000/0000-00");
    });

    element.bind('paste', function (e) {
      $(this).mask("00.000.000/0000-00");
      return true;
    });
  }

}

function validatorCpfCnpj(){

  $.validator.addMethod("cpfcnpj", function (cpfcnpj) {
    return validateCpfCnpj(cpfcnpj, null);
  });

}

function validatorCpf(){

  $.validator.addMethod("cpf", function (cpf) {
    return validateCpfCnpj(cpf,'CPF');
  });

}

function validatorCnpj(){

  $.validator.addMethod("cnpj", function (cnpj) {
    return validateCpfCnpj(cnpj,'CNPJ');
  });

}

function validateCpfCnpj (cpfcnpj, valida){
  if (cpfcnpj === "") {
    return true;
  }

  function calc_digitos_posicoes(digitos, posicoes) {
    var  soma_digitos = 0;

    for (var  i = 0; i < digitos.length; i++) {
      soma_digitos = soma_digitos + digitos[i] * posicoes;
      posicoes--;
      if (posicoes < 2) {
        posicoes = 9;
      }
    }
    soma_digitos = soma_digitos % 11;
    if (soma_digitos < 2) {
      soma_digitos = 0;
    } else {
      soma_digitos = 11 - soma_digitos;
    }
    var  cpf = digitos + soma_digitos;
    return cpf;
  }

  cpfcnpj = cpfcnpj.toString();
  cpfcnpj = cpfcnpj.replace(/[^0-9]/g, '');

  if (valida === null && cpfcnpj.length === 11) {
    valida = 'CPF';
  } else if (valida === null && cpfcnpj.length === 14) {
    valida = 'CNPJ';
  } else if (valida === null) {
    return false;
  }

  if (valida === 'CPF') {

    if (cpfcnpj == "00000000000" ||
      cpfcnpj == "11111111111" ||
      cpfcnpj == "22222222222" ||
      cpfcnpj == "33333333333" ||
      cpfcnpj == "44444444444" ||
      cpfcnpj == "55555555555" ||
      cpfcnpj == "66666666666" ||
      cpfcnpj == "77777777777" ||
      cpfcnpj == "88888888888" ||
      cpfcnpj == "99999999999") {
      return false;
    }

    var  digitos = cpfcnpj.substr(0, 9);
    var  novo_cpf = calc_digitos_posicoes(digitos, 10);
    novo_cpf = calc_digitos_posicoes(novo_cpf, 11);
    if (novo_cpf === cpfcnpj) {
      return true;
    } else {
      return false;
    }
  } else if (valida === 'CNPJ') {
    var  cnpj_original = cpfcnpj;
    var  primeiros_numeros_cnpj = cpfcnpj.substr(0, 12);
    var  primeiro_calculo = calc_digitos_posicoes(primeiros_numeros_cnpj, 5);
    var  segundo_calculo = calc_digitos_posicoes(primeiro_calculo, 6);
    var  cnpj = segundo_calculo;
    if (cnpj === cnpj_original) {
      return true;
    }
    return false;
  }
  else {
    return false;
  }
}

function validatorConfig(){
  //Setando classes para a validação do formulário
  $.validator.setDefaults({
    highlight: function (e) {
      $(e).closest(".form-group").addClass("has-danger");
    },
    unhighlight: function (e) {
      $(e).closest(".form-group").removeClass("has-danger");
    },
    errorElement: "div",
    errorClass: "form-control-feedback small",
    errorPlacement: function (e, a) {
      a.closest(".form-group").length ? e.appendTo(a.closest(".form-group")) : e.insertAfter(a);
    }
  });
}

function formValidateConfig ( form ){
  form.each(function () {
    $(this).validate({
      rules: {
        email: {
          emailCustom: true,
          minlength: 10
        },
        cpfcnpj: {
          cpfcnpj: true
        },
        cpf: {
          cpf: true
        },
        cnpj: {
          cnpj: true
        },
        cep: {
          cep: true
        },
        dealership: {
          required: true,
          minlength: 1,
        },
        phone: {
          phone_validator: true
        },
        lgpd: {
          required: true,
        },
        birthdate:{
          birthdate_validator: true,
        }
      },
      messages: {
        name: "Campo obrigatório",
        surname: "Campo obrigatório",
        fullname: "Campo obrigatório",
        email: {
          required: "Campo obrigatório",
          email: "Insira um e-mail válido",
          minlength: "Insira um e-mail válido",
          emailCustom: "Insira um e-mail válido"
        },
        birthdate: {
          required: "Campo obrigatório",
          birthdate_validator: "Usuário menor de idade"
        },
        phone: {
          required: "Campo obrigatório",
          phone_validator: "Informe um telefone válido",
        },
        city: "Por favor escolha uma cidade",
        dealership: "Por favor escolha uma concessionária",
        message: "Campo obrigatório",
        cpfcnpj: {
          required: "Campo obrigatório",
          cpfcnpj: "Informe um CPF/CNPJ válido"
        },
        cpf: {
          required: "Campo obrigatório",
          cpf: "Informe um CPF válido"
        },
        cnpj: {
          required: "Campo obrigatório",
          cnpj: "Informe um CNPJ válido"
        },
        cep: {
          required: "Campo obrigatório",
          cep: "Informe um CEP válido"
        },
        lgpd: {
          required: "Para continuar você precisa declarar que aceita os termos e condições de privacidade e proteção de dados"
        },
      }
    });
  });
}

function loadCityByCep(cepElement, cityElement){

  if (cepElement.length > 0 && cityElement.length > 0 ) {

    cepElement.change(function (event) {
      var  cepValue = $(this).val();

      //verifica se o cep digitado é valido
      if ( /^[0-9]{5}-[0-9]{3}$/.test(cepValue) ){

        //consulta a cidade do cep em questao
        $.ajax({
          url: ajaxHandler.url,
          type: 'POST',
          data: {
            action: 'jc_ajax_manage_geocode',
            cep: cepValue
          },
          success: function (response) {
            if(response && response.success ){
                var  userCity = response.data.endereco.localidade;
                var  userUf = response.data.endereco.uf;

                if( cityElement.find("option[value='" + userCity + " - " + userUf +  "']").length > 0 ){
                  cityElement.val( userCity + " - " + userUf );
                  cityElement.trigger('change');
                }
            }
          }
        });
      }
    });

  }
}

function loadDealershipByCity( cityElement ){

  if (cityElement.length > 0) {

    cityElement.change(function (event) {

      if(!event.originalEvent){
        return;
      }

      var  cityName = $(this).val();
      var  dealerships = $(this).closest('.form').find('.dealerships');

      if (cityName) {
        dealerships.show();
        dealerships.find('.radio').each(function (index, el) {
          var  elcityName = $(el).data('city');

          //Resetando todos os check de concessionárias
          $(el).find('input:radio').prop('checked', false);

          if (cityName.length > 0) {
            if (cityName != elcityName) {
              $(el).hide();
            } else {
              $(el).show();
            }
            dealerships.prop('disabled', false);
          } else {
            $(el).hide();
            dealerships.val('');
            dealerships.prop('disabled', true);
          }
        });
      } else {
        dealerships.hide();
        dealerships.find('input:radio').removeAttr('checked');
      }
    });

  }

}

function setLeadIdSessionStorage(leadID){
  if(typeof(Storage)){
    sessionStorage.setItem('_jc', leadID);
  }
}

function populateAndShowFormError( form, errors ){

  form.find(".form-error .errors-list").text(' ');

  $.each(errors, function (index, element) {
    form.find(".form-error .errors-list").append('<li>' + element + '</li>');
  });

  showFormError(form);

}

function showFormError(form){
  $(".form-error").css("display", "block");
}


